const initializeApp = async () => {
  const { default: initializeGlobalVars } = await import(
    '@anova/crude-vars/initialize-globals'
  );
  initializeGlobalVars();

  // @ts-ignore
  window.__DOMAIN__ =
    // @ts-ignore
    window['__ANOVA-VARS__'].global.DOMAIN || process.env.REACT_APP_DOMAIN;
  // TODO: TO REMOVE AFTER ALL MODULES USE NEW IMPLEMENTATION
  import('./bootstrap');

  const { default: vars } = await import('@anova/crude-vars');
  // @ts-ignore
  window.__DOMAIN__ = vars.global.DOMAIN || process.env.REACT_APP_DOMAIN;
  // @ts-ignore
  window.MICRO_FRONTEND = true;

  const [renderer, appModule] = await Promise.all([
    import('./renderer'),
    import('./App'),
  ]);

  const baseRenderer = renderer.default;
  const App = appModule.default;
  baseRenderer(App);
};

initializeApp();

/**
 * @exports
 * Initialization method for testing purposes
 */
export const testInit = initializeApp;

export default undefined;
